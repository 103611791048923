export const ReactRightSideComponent = () => {
  return (
    <div>
      <div className="w-[100%] lg:w-[80%] lg:h-[20vh]  content-center text-center p-4 inline-block">
        <h1 className="text-primary-400 font-body text-4xl lg:text-7xl">
          Rey Messon
        </h1>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400" />

      <div className="hidden lg:block lg:m-5">
        <p>
          With over a decade as a dedicated Software Engineer in the
          Telecommunication, core banking, and Maritime terminal industries, I
          am committed to delivering optimal solutions. Notably, I have
          contributed significantly as a sub-contractor maintaining Verizon's
          key software. My portfolio boasts successful completion of more than
          30 projects. I am eager to bring my extensive skills and industry
          knowledge to your esteemed company, aiming to contribute effectively
          as a Software Developer. Looking ahead, I am excited about advancing
          my career with a Master's degree in Artificial Intelligence, where I
          aim to deepen my understanding and contribute to groundbreaking
          advancements in technology.
        </p>
      </div>

      <div className="block lg:mt-20 lg:ml-5">
        <div className="block lg:text-start">
          <h1 className="text-primary-400 font-roboto lg:mb-5 font-bold text-2xl">
            Experience
          </h1>
        </div>
      </div>

      <div className="block w-[95%] lg:w-[90%] lg:h-[3px] lg:m-5 bg-primary-400" />

      <div className="block lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            FULL STACK - SOFTWARE ENGINEER (REACT / NODEJS)
          </h1>
          <h1 className="text-primary-400 font-roboto font-normal text-sm">
            eSIMflys.com SL, Colombia
          </h1>
          <h1 className="text-primary-400 font-robotofont-normal text-sm">
            Jan 2024 - Current
          </h1>
          <h1 className="text-primary-400 font-roboto mt-5 font-bold text-sm">
            REACT / TAILWINDCSS / NODEJS
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              Experienced in developing scalable web applications with a focus
              on <span class="font-bold">clean architecture</span> and
              internationalization. Proficient in utilizing a wide array of
              technologies including React, Node.js, and{" "}
              <span class="font-bold">TailwindCSS</span> to deliver robust
              solutions. Skilled in integrating third-party APIs like Shopify
              and Adyen for seamless e-commerce functionalities. Adept at
              implementing user authentication with{" "}
              <span class="font-bold">Google SignIn</span> and managing state
              with <span class="font-bold">Redux</span>{" "}
              for efficient data flow. Experienced in using{" "}
              <span class="font-bold">Firebase Analytics</span>{" "}
              and Google Analytics for monitoring user engagement and optimizing
              user experience.
            </p>
            <br />
            <div className="mb-4">
              <h3 className="text-sm font-semibold mb-2">
                Technologies Utilized:
              </h3>
              <ul className="list-disc list-inside">
                <li>
                  Frontend: React (including React Router, Redux), TailwindCSS,
                  Axios, React Icons
                </li>
                <li>
                  Backend: Node.js, Express.js, MongoDB (with Mongoose), JWT for
                  authentication
                </li>
                <li>
                  API Integrations: Shopify API (via GraphQL), Adyen for
                  payments
                </li>
                <li>
                  Utilities & Libraries: Moment.js for date handling, Nodemailer
                  for email notifications, bcrypt for password hashing
                </li>
                <li>
                  Other Tools: Multer for file uploads, Sharp for image
                  processing
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-2">
                Project Highlights:
              </h3>
              <p>
                <span className="font-semibold">esimflys.com</span> - Developed
                a multi-language web application from scratch based on Figma
                designs, encompassing features such as user login, profile
                management, and subscription plans. Integrated Google SignIn for
                user authentication and leveraged Firebase Analytics for
                comprehensive user behavior analysis. Implemented clean
                architecture principles to maintain scalability and code
                readability.
              </p>

              <p className="mt-4">
                <span className="font-semibold">Localization:</span> Managed
                application localization in 6 languages using Tolgee and an
                in-house tool for key management and translation.
              </p>

              <p className="mt-4">
                <span className="font-semibold">Analytics:</span> Implemented
                Firebase Analytics and Google Analytics for detailed insights
                into user interaction and behavior, optimizing user flows with
                GA4 Funnels reports.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            REACT ENGINEER
          </h1>
          <h1 className="text-primary-400 font-roboto font-normal text-sm">
            Skymax, Santo Domingo, DR.
          </h1>
          <h1 className="text-primary-400 font-robotofont-normal text-sm">
            May 2021 - Dec 2022
          </h1>
          <h1 className="text-primary-400 font-roboto mt-5 font-bold text-sm">
            REACT / BOOTSTRAP / NODEJS / MONGODB
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              React - I created a Jewelry eCatalog app with NodeJS: - I
              created a module to upload a picture and product detail using an
              iPad, and then the backend receives that Data, also I created the
              module to do the maintenance to the application like a CRUD from
              any iOS Device iPhone, or iPad. - The application using SwiftUI is
              able to add the views like Login, ContentView, Product, Product
              Item, FilterView, and Favorite View, one thing that I feel so
              proud of in this project is that I implemented an ImageCache to
              improve the speed of the rendered picture when the app is running.
              - Right now I'm exploring the technologies to use in a new project
              as State Management, Type Inference, and Distributed
              actor isolation, hoping to the next project injected these new
              features.
            </p>
          </div>
        </div>
      </div>
      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            ANGULAR ENGINEER
          </h1>
          <h1 className="text-primary-400 font-roboto font-normal text-sm">
            Newtech, Santo Domingo, DR.
          </h1>
          <h1 className="text-primary-400 font-robotofont-normal text-sm">
            October 2015 - April 2021
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              On the SMD project, I made the migration from Sencha EXTJS App to Angular
              portal view on the Genesys project, it’s related to finding
              information from XML request and showing it on  Verizon
              Desktop app. I was part of the implementation of the Human Resource
              Angular app using Typescript. The View Circuit in the Angular app, lets the
              user see the status of Verizon's devices that belong to the
              customer but are under the Verizon administration. - Archive
              Module migration; as an SMD developer, I create from the scratch
              the new functionality of the Tablview
            </p>
          </div>
        </div>
      </div>

      <div className="mt-10 lg:mt-20 lg:ml-5">
        <div className="block lg:text-start">
          <h1 className="text-primary-400 font-roboto lg:mb-5 font-bold text-2xl">
            Academic Background
          </h1>
        </div>
      </div>

      <div className="block w-[95%] lg:w-[90%] lg:h-[3px] lg:m-5 bg-primary-400" />

      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            PONTIFICIA UNIVERSIDAD CATOLICA MADRE & MAESTRA
          </h1>
          <h1 className="text-primary-400 italic font-roboto font-normal text-sm">
            Bachelor of Software Engineer
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              Scholastic Competition in ACM-ICPC - During the signature Advanced
              Web Development I had to create a Shopping Cart in Reactjs that
              connect to AWS Alexa, DynamoDB using echo. I applied my knowledge
              about Kafka in a project regarding a semaphore where is sent to
              the topics the lights and the time using nodejs as producer. And
              then I added a mobile application developed in Kotlin as a
              consumer that sends I signal to the semaphore telling that an old
              person needs to cross the street, I used socket.io in order to
              communicate the devices. I applied my knowledge regarding OpenCV
              in a project where I needed to compare two faces. And then I added
              a putText where show the name of the personal detected. In order
              to get more quality at the image added C++ with JNI. And another
              project I used OpenCV to read hand gestures. I created a
              mini-youtube page using ElasticTransCoder that reproduce video in
              240p, 360p, 480p,720p 1020p, HD, using S3. I created also an
              application that unblocks the users using voice recognition with
              Powershell, active directory, nodejs, Asterisk and AWS EC2. As the
              final project of my career, I solved the issue that the people
              were not able to find a good candidate to make the tasks that they
              need at home all anywhere they needed, so I developed an Auction
              system that solves this issue and the user is enabled to create
              auction section in order to get the best technical person and the
              best prices. Using MongoDB, Angular, Nodejs, GraphQL,
              DigitalOcean, Bootstrap. I created it from the scratch.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10 lg:mt-20 lg:ml-5">
        <div className="block lg:text-start">
          <h1 className="text-primary-400 font-roboto lg:mb-5 font-bold text-2xl">
            CERTIFICATIONS
          </h1>
        </div>
      </div>

      <div className="block w-[95%] lg:w-[90%] lg:h-[3px] lg:m-5 bg-primary-400" />

      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 italic font-roboto font-normal text-sm">
            Advanced Software Engineer Skills certifications
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              Web Development: HTML5, Bootstrap, CSS, SCSS, Javascript, Jquery,
              Angular, React Redux, ReactNative Cloud Platform: AWS IoT,
              Containerized Application on AWS, AWS Fundamentals - Building
              Serverless Applications, Migrating to the Cloud, Addressing
              Security Risk, Going CloudNative, AWS Machine Learning. Mobile
              Development: Android - Kotlin and Java. Project Management: Agile,
              SCRUM Shopify Specialist: E-Commerce Workshop
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
