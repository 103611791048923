import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import { MobileComponent } from './MobileComponent';
import { ReactComponent } from './ReactComponent';
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB2UB4mlPwasqAkDNt6Hd_siWddg4Rv8w0",
  authDomain: "resume-check.firebaseapp.com",
  projectId: "resume-check",
  storageBucket: "resume-check.appspot.com",
  messagingSenderId: "851916803770",
  appId: "1:851916803770:web:a7bc661bcce50291b27c83",
  measurementId: "G-HYP73QKX27"
};

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

analytics.setAnalyticsCollectionEnabled(true);

// analytics.debug_mode_enabled = true;

const router = createBrowserRouter([
  {
    path: "/",
    element: <App 
      analytics={analytics}
    />,
  },
  {
    path: "/ios",
    element: <MobileComponent 
      analytics={analytics}
    />,
  },
  {
    path: "/react",
    element: <ReactComponent 
      analytics={analytics}
    />,
  },
]);

export default router;
