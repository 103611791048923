export const MobileRightSideComponent = () => {
  return (
    <div>
      <div className="w-[100%] lg:w-[80%] lg:h-[20vh]  content-center text-center p-4 inline-block">
        <h1 className="text-primary-400 font-body text-4xl lg:text-7xl">
          Rey Messon
        </h1>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400" />

      <div className="hidden lg:block lg:m-5">
        <p>
          With over a decade as a dedicated Software Engineer in the
          Telecommunication, core banking, and Maritime terminal industries, I
          am committed to delivering optimal solutions. Notably, I have
          contributed significantly as a sub-contractor maintaining Verizon's
          key software. My portfolio boasts successful completion of more than
          30 projects. I am eager to bring my extensive skills and industry
          knowledge to your esteemed company, aiming to contribute effectively
          as a Software Developer. Looking ahead, I am excited about advancing
          my career with a Master's degree in Artificial Intelligence, where I
          aim to deepen my understanding and contribute to groundbreaking
          advancements in technology.
        </p>
      </div>

      <div className="block lg:mt-20 lg:ml-5">
        <div className="block lg:text-start">
          <h1 className="text-primary-400 font-roboto lg:mb-5 font-bold text-2xl">
            Experience
          </h1>
        </div>
      </div>

      <div className="block w-[95%] lg:w-[90%] lg:h-[3px] lg:m-5 bg-primary-400" />

      <div className="block lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            IOS ENGINEER - TOP #30 APPSTORE
          </h1>
          <h1 className="text-primary-400 font-roboto font-normal text-sm">
            Holafly SL, Dublin Irlanda
          </h1>
          <h1 className="text-primary-400 font-robotofont-normal text-sm">
            Jan 2023 - Current
          </h1>
          <h1 className="text-primary-400 font-roboto mt-5 font-bold text-sm">
            SWIFTUI
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              I developed the application from scratch. It began to be developed
              according to the scheme shown in{" "}
              <span class="font-bold">Figma</span>, with around 30 to 50
              screens, these included the login view, guide view, profile view,
              and Login and authentication with Gmail view, and the rest also
              included the consumption plan view. Information that was consumed
              from the backend through <span class="font-bold">Alamofire</span>.
              This app was built using <span class="font-bold">CLEAN</span>
              Architecture and <span class="font-bold">MVVM</span>. Localizable
              To maintain the application in 6 languages, two tools were needed,{" "}
              <span class="font-bold">Tolgee</span> and an internal application
              developed in-house in which it allowed us to generate the keys and
              be able to manage 6 languages.{" "}
              <span class="font-bold">Adyen</span> We have all the company's
              inventory in Shopify since this information was consumed via API
              for the user to select, and through Adyen, the Checkout was
              carried out. With GraphQL the calls made to the{" "}
              <span class="font-bold">Shopify</span> APIs were made. During the
              implementation, we had problems because Shopify at that time did
              not have a native solution for consumption from Swift but through
              a WebView, and what we needed was to give a 100% native solution
              to the user.{" "}
              <span class="font-bold">
                Manifest Privacy file - May 1st, 2024
              </span>
              . Apple released the resolution that all applications that have
              not declared in their manifesto the privacy details that
              third-party applications manage would be blocked. The solution to
              this involved building a document in Xcode and declaring the
              AppStore Connect store. <span class="font-bold">Rating</span> To
              motivate users to show their reviews in the AppStore, the Rating
              screen was implemented using the{" "}
              <span class="font-bold">StoreKit</span> library. Intercom As part
              of the customer service to application users, we worked on the
              implementation of the intercom.com service interface, which is a
              direct conversation with the user through the online customer
              service platform, implementation requires a review like this such
              as the call to the APIs of said company that provides the service.
              UXCam To be able to have a heat map and go a little deeper into
              the user experience in the application. There are other tools, for
              example, such as <span class="font-bold">UXCam</span>, which
              allows you to know in detail what those specific places are in
              which the user scrolls, taps on a button, etc.{" "}
              <span class="font-bold">Firebase Analytics</span> After the
              application achieved a good level of user usage and daily
              frequency. Analytics were implemented to identify which screens
              users frequent most. Through Funnels reports with GA4 we follow
              the daily flow of users step by step.
            </p>
          </div>
        </div>
      </div>

      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            IOS ENGINEER
          </h1>
          <h1 className="text-primary-400 font-roboto font-normal text-sm">
            Skymax, Santo Domingo, DR.
          </h1>
          <h1 className="text-primary-400 font-robotofont-normal text-sm">
            May 2021 - Dec 2022
          </h1>
          <h1 className="text-primary-400 font-roboto mt-5 font-bold text-sm">
            SWIFTUI
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              Swift iOS - I created a Jewelry eCatalog app with Xcode: - I
              created a module to upload a picture and product detail using an
              iPad, and then the backend receives that Data, also I created the
              module to do the maintenance to the application like a CRUD from
              any iOS Device iPhone, or iPad. - The application using SwiftUI is
              able to add the views like Login, ContentView, Product, Product
              Item, FilterView, and Favorite View, one thing that I feel so
              proud of in this project is that I implemented an ImageCache to
              improve the speed of the rendered picture when the app is running.
              - Right now I'm exploring the technologies to use in a new project
              as StateObject, Swift Snippets, Type Inference, and Distributed
              actor isolation, hoping to the next project injected these new
              features.
            </p>
          </div>
        </div>
      </div>
      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            IOS ENGINEER
          </h1>
          <h1 className="text-primary-400 font-roboto font-normal text-sm">
            Newtech, Santo Domingo, DR.
          </h1>
          <h1 className="text-primary-400 font-robotofont-normal text-sm">
            October 2015 - April 2021
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              On the SMD project, I developed the iOS version on Swift for the
              portal view on the Genesys project, it’s related to finding
              information from XML request and showing it on an iOS Verizon
              Mobile app. I was part of the implementation of the Human Resource
              iOS app using Swift. The View Circuit in the iOS app, lets the
              user see the status of Verizon's devices that belong to the
              customer but are under the Verizon administration. - Archive
              Module migration; as an SMD developer, I create from the scratch
              the new functionality of the Tablview
            </p>
          </div>
        </div>
      </div>

      <div className="mt-10 lg:mt-20 lg:ml-5">
        <div className="block lg:text-start">
          <h1 className="text-primary-400 font-roboto lg:mb-5 font-bold text-2xl">
            Academic Background
          </h1>
        </div>
      </div>

      <div className="block w-[95%] lg:w-[90%] lg:h-[3px] lg:m-5 bg-primary-400" />

      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 font-roboto mb-5 font-bold text-sm">
            PONTIFICIA UNIVERSIDAD CATOLICA MADRE & MAESTRA
          </h1>
          <h1 className="text-primary-400 italic font-roboto font-normal text-sm">
            Bachelor of Software Engineer
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              Scholastic Competition in ACM-ICPC - During the signature Advanced
              Web Development I had to create a Shopping Cart in Reactjs that
              connect to AWS Alexa, DynamoDB using echo. I applied my knowledge
              about Kafka in a project regarding a semaphore where is sent to
              the topics the lights and the time using nodejs as producer. And
              then I added a mobile application developed in Kotlin as a
              consumer that sends I signal to the semaphore telling that an old
              person needs to cross the street, I used socket.io in order to
              communicate the devices. I applied my knowledge regarding OpenCV
              in a project where I needed to compare two faces. And then I added
              a putText where show the name of the personal detected. In order
              to get more quality at the image added C++ with JNI. And another
              project I used OpenCV to read hand gestures. I created a
              mini-youtube page using ElasticTransCoder that reproduce video in
              240p, 360p, 480p,720p 1020p, HD, using S3. I created also an
              application that unblocks the users using voice recognition with
              Powershell, active directory, nodejs, Asterisk and AWS EC2. As the
              final project of my career, I solved the issue that the people
              were not able to find a good candidate to make the tasks that they
              need at home all anywhere they needed, so I developed an Auction
              system that solves this issue and the user is enabled to create
              auction section in order to get the best technical person and the
              best prices. Using MongoDB, Angular, Nodejs, GraphQL,
              DigitalOcean, Bootstrap. I created it from the scratch.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10 lg:mt-20 lg:ml-5">
        <div className="block lg:text-start">
          <h1 className="text-primary-400 font-roboto lg:mb-5 font-bold text-2xl">
            CERTIFICATIONS
          </h1>
        </div>
      </div>

      <div className="block w-[95%] lg:w-[90%] lg:h-[3px] lg:m-5 bg-primary-400" />

      <div className="block mt-10 lg:mt-20 lg:ml-5">
        <div className="block text-start">
          <h1 className="text-primary-400 italic font-roboto font-normal text-sm">
            Advanced Software Engineer Skills certifications
          </h1>
          <div className="text-primary-400 font-roboto mt-5 font-normal text-sm">
            <p>
              Web Development: HTML5, Bootstrap, CSS, SCSS, Javascript, Jquery,
              Angular, React Redux, ReactNative Cloud Platform: AWS IoT,
              Containerized Application on AWS, AWS Fundamentals - Building
              Serverless Applications, Migrating to the Cloud, Addressing
              Security Risk, Going CloudNative, AWS Machine Learning. Mobile
              Development: Android - Kotlin and Java. Project Management: Agile,
              SCRUM Shopify Specialist: E-Commerce Workshop
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
