import { useEffect } from "react";
import "./App.css";
import { LeftSideComponent } from "./components/LeftSideComponent";
import { ReactLeftSideComponent } from "./components/React/ReactLeftSideComponent";
import { ReactRightSideComponent } from "./components/React/ReactRightSideComponent";
import { RightSideComponent } from "./components/RightSideComponent";

export const ReactComponent = (props) => {

  useEffect(() => {
    let newEvent = {
      "name": "react_open_view",
      "action": "open",
      "button": "open_button",
      "view": "HomeReactSideView"
    }
    props.analytics.logEvent(newEvent, "react_open_view");
  })

  return (
    <div className="w-full">
      <div className="flex flex-row lg:flex lg:flex-row p-10">
        <div className="hidden lg:block lg:w-1/4 lg:bg-blue-900 lg:mr-5 lg:h-[100vh]">
          <ReactLeftSideComponent
            analytics={props.analytics}
          />
        </div>
        <div className="w-full lg:block lg:w-3/4 ">
          <ReactRightSideComponent/>
        </div>
      </div>
    </div>
  );
}