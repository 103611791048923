export const LeftSideComponent = () => {
  return (
    <div className="lg-flex lg:flex-cols-3 lg:bg-blue-900">
      <div className="lg:w-[80%] lg:h-[20vh]  inline-block p-4 m-5">
        <div className=" text-center  ">
          <h1 className="text-secundary-500 font-body">Rey Messon</h1>
        </div>
      </div>
      <div className="lg:w-[90%] lg:h-[5vh]  inline-block p-4 m-5">
        <div className=" text-end">
          <h1 className="text-primary-100 font-roboto font-bold text-xl">
            iOS ENGINEER
          </h1>
        </div>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400 " />

      <div className="lg:w-[90%] lg:h-[20vh] inline-block p-4 m-5">
        <div className=" text-end">
          <h1 className="text-primary-100 font-roboto mb-5 font-bold text-xl">
            Personal Info
          </h1>
          <h1 className="text-success-100 font-roboto">+ 1 809 844 3270</h1>
          <h1 className="text-success-100 font-roboto">me@reymesson.com</h1>
          <h1 className="text-success-100 font-roboto">
            https://github.com/reymesson1
          </h1>
        </div>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400 " />

      <div className="lg:w-[90%] lg:h-[40vh] inline-block p-4 m-5">
        <div className=" text-end">
          <h1 className="text-primary-100 font-roboto mb-5 font-bold text-xl">
            Expertise
          </h1>
        </div>
        <div className=" text-start">
          <ul className="list-disc m-5 text-sm text-success-100 font-roboto font-bold">
            <li>TEAM WORK</li>
            <li>SOFTWARE DEVELOPMENT</li>
            <li>CREATIVE DESIGN</li>
            <li>COMPLEX PROBLEM SOLVER</li>
            <li>INNOVATIVE</li>
            <li>PROJECT MANANAGEMENT</li>
            <li>SOLID PRINCIPLE PASSIONATED</li>
            <li>ADVOCATE CLEAN ARCHITECTURE</li>
          </ul>
        </div>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400 " />

      <div className="lg:w-[90%] lg:h-[40vh] inline-block p-4 m-5">
        <div className=" text-end">
          <h1 className="text-primary-100 font-roboto mb-5 font-bold text-xl">
            Skills:
          </h1>
        </div>
        <div className="text-end context-center">
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>CLEAN ARCHITECTURE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>MVVM</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>AWS</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>ALAMOFIRE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[20%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>OBJECT-C</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[80%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>COORDINATOR</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[20%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>UI-KIT</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[70%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>COMBINE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[70%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>FACTORY</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>SWIFTUI</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>SOLID PRINCIPLES</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>CORE LOCATION</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>STORE KIT</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>Rx SWIFT</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>SHOPIFY</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>ADYEN</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>GRAPHQL</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>TOLGEE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>TESTFLIGHT</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>GOOGLE AUTH</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>SWIFTUI</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[60%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>A/B TESTING</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[60%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>CI/CD PIPELINE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[75%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>XCTEST</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>PRIVACY MANIFEST</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>USER DEFAULT</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>FIREBASE CODE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
              <div className="lg:w-[90%] lg:h-[4px] m-5 bg-yellow-700" />              
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>FIREBASE MESSAGE</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
             
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>&nbsp;</h1>
              </div>
            </div>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-2/5">
             
            </div>
            <div className="lg:w-3/5">
              <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                <h1>&nbsp;</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
