import "./App.css";
import React, { useEffect } from "react";
import { MobileComponent } from "./MobileComponent";

const App = (props) => {

  useEffect(() => {
    props.analytics.setAnalyticsCollectionEnabled(true);
    props.analytics.logEvent("page_view", { page_path: window.location.pathname });
  }, [props.analytics]);

  return (
    <section>
      <div>
        <MobileComponent
          analytics={props.analytics}
        />
      </div>
    </section>
  );
}

export default App;