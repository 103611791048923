import "./App.css";
import { useEffect } from "react";
import { MobileLeftSideComponent } from "./components/Mobile/MobileLeftSideComponent";
import { MobileRightSideComponent } from "./components/Mobile/MobileRightSideComponent";

export const MobileComponent = (props) => {

  useEffect(() => {
    let newEvent = {
      "name": "ios_open_view",
      "action": "open",
      "button": "open_button",
      "view": "HomeiOSSideView"
    }
    props.analytics.logEvent(newEvent, "ios_open_view");
  })

  return (
    <div className="w-full">
      <div className="flex flex-row lg:flex lg:flex-row p-10">
        <div className="hidden lg:block lg:w-1/4 lg:bg-blue-900 lg:mr-5 lg:h-[100vh]">
          <MobileLeftSideComponent
            analytics={props.analytics}
          />
        </div>
        <div className="w-full lg:block lg:w-3/4 ">
          <MobileRightSideComponent/>
        </div>
      </div>
    </div>
  );
}