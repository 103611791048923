import React from "react";

export const MobileLeftSideComponent = (props) => {
  const expertises = [
    "TEAM WORK",
    "SOFTWARE DEVELOPMENT",
    "CREATIVE DESIGN",
    "COMPLEX PROBLEM SOLVER",
    "INNOVATIVE",
    "PROJECT MANANAGEMENT",
    "SOLID PRINCIPLE PASSIONATED",
    "ADVOCATE CLEAN ARCHITECTURE",
  ];

  const skills = [
    { name: "CLEAN ARCHITECTURE", percentage: "90" },
    { name: "MVVM", percentage: "90" },
    { name: "AWS", percentage: "80" },
    { name: "ALAMOFIRE", percentage: "90" },
    { name: "OBJECT-C", percentage: "30" },
    { name: "COORDINATOR", percentage: "90" },
    { name: "UI-KIT", percentage: "30" },
    { name: "COMBINE", percentage: "70" },
    { name: "FACTORY", percentage: "90" },
    { name: "SWIFTUI", percentage: "90" },
    { name: "SOLID PRINCIPLES", percentage: "90" },
    { name: "CORE LOCATION", percentage: "70" },
    { name: "STORE KIT", percentage: "70" },
    { name: "Rx SWIFT", percentage: "70" },
    { name: "SHOPIFY", percentage: "80" },
    { name: "ADYEN", percentage: "80" },
    { name: "GRAPHQL", percentage: "70" },
    { name: "TOLGEE", percentage: "80" },
    { name: "TESTFLIGHT", percentage: "90" },
    { name: "GOOGLE AUTH", percentage: "90" },
    { name: "SWIFT", percentage: "85" },
    { name: "A/B TESTING", percentage: "60" },
    { name: "CI/CD PIPELINE", percentage: "70" },
    { name: "XCTEST", percentage: "70" },
    { name: "PRIVACY MANIFEST", percentage: "80" },
    { name: "USER DEFAULT", percentage: "70" },
    { name: "FIREBASE CORE", percentage: "70" },
    { name: "FIREBASE MESSAGE", percentage: "90" },
    { name: "XCODE", percentage: "80" },
    { name: "SKELETON", percentage: "70" },
    { name: "CORE DATA", percentage: "80" },
  ];

  const onHandleEventFirebase = ( () => {
    props.analytics.logEvent("click_github_link", { page_path: "https://github.com/reymesson1" });
    window.open("https://github.com/reymesson1", "_blank")
  })


  return (
    <div className="lg-flex lg:flex-cols-3 lg:bg-blue-900">
      {/* Left Side Content */}
      <div className="lg:w-[80%] lg:h-[20vh]  inline-block p-4 m-5">
        <div className="text-center">
          <h1 className="text-secundary-500 font-body">Rey Messon</h1>
        </div>
      </div>

      {/* Middle Content */}
      <div className="lg:w-[90%] lg:h-[5vh]  inline-block p-4 m-5">
        <div className="text-end">
          <h1 className="text-primary-100 font-roboto font-bold text-xl">
            iOS ENGINEER
          </h1>
        </div>
      </div>

      {/* Divider */}
      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400" />

      {/* Personal Info */}
      <div className="lg:w-[90%] lg:h-[20vh] inline-block p-4 m-5">
        <div className="text-end">
          <h1 className="text-primary-100 font-roboto mb-5 font-bold text-xl">
            Personal Info
          </h1>
          <h1 className="text-success-100 font-roboto">+1 809 844 3270</h1>
          <h1 className="text-success-100 font-roboto">me@reymesson.com</h1>
          <h1 onClick={onHandleEventFirebase} className="text-success-100 font-roboto underline">
            https://github.com/reymesson1
          </h1>
        </div>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400" />

      <div className="lg:w-[90%] lg:h-[40vh] inline-block p-4 m-5">
        <div className="text-end">
          <h1 className="text-primary-100 font-roboto mb-5 font-bold text-xl">
            Expertise
          </h1>
        </div>
        <div className="text-start">
          <ul className="list-disc m-5 text-sm text-success-100 font-roboto font-bold">
            {expertises.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="lg:w-[90%] lg:h-[2px] m-5 bg-primary-400" />

      <div className="lg:w-[90%] lg:h-[40vh] inline-block p-4 m-5">
        <div className="text-end">
          <h1 className="text-primary-100 font-roboto mb-5 font-bold text-xl">
            Skills
          </h1>
        </div>
        <div className="text-end context-center">
          {skills.map((skill, index) => (
            <div key={index} className="lg:flex flex-row">
              <div className="lg:w-2/5">
                  <div className={`lg:w-[${skill.percentage}%] lg:h-[4px] m-5 bg-yellow-700`} />
              </div>
              <div className="lg:w-3/5">
                <div className="text-primary-100 font-roboto pt-3 mb-5 text-xs">
                  <h1>{skill.name}</h1>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
